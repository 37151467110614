<template>
  <div class="white--text grey lighten-3 pa-0" fluid>
    <simple-header
      hasLeftChild
      :routes="[{ title: 'خانه', url: '/' }, { title: 'دسته بندی' }]"
      title="دسته بندی"
    >
      <v-img
        :src="
          require('../../assets/icons/chat-comment-oval-speech-bubble-with-text-lines.png')
        "
        class="sp-img white-icon mr-md-auto ml-md-0 mx-auto"
      />
    </simple-header>
    <v-container>
      <v-row v-if="loading">
        <v-col sm="6" md="4" lg="3" cols="12" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          sm="6"
          md="4"
          lg="3"
          cols="12"
          v-for="(category, i) in categories"
          :key="i"
          @click="category.slug && $router.push('/' + category.slug)"
        >
          <article-item
            :img="
              category.media
                ? category.media.url
                : require('../../assets/img/thumb.jpg')
            "
            :title="category.name"
            :withDate="false"
          />
        </v-col>
      </v-row>
      <my-paginate v-model="page" :length="last_page" @onChange="GetData" />
    </v-container>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import ArticleItem from "../../components/Pages/ArticleItem.vue";
import SimpleHeader from "../../components/SimpleHeader.vue";
import MyAxios from "../../constants/MyAxios";
export default {
  created() {
    this.GetData();
  },
  components: { SimpleHeader, ArticleItem, MyPaginate },
  data() {
    return {
      loading: true,
      categories: [],
      last_page: 1,
      page: 1,
    };
  },
  methods: {
    GetData: function() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/categories/index", {
        params: {
          with: "media",
          page: this.page,
          conditions: {
            parent_id: 6,
          },
        },
      })
        .then((response) => {
          console.log(response.data);
          this.categories = response.data.data;
          this.loading = false;
          this.last_page = response.data.last_page;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 100px;
  max-height: 100px;
}
</style>
